const GA_TRACKING_ID = process.env.REACT_APP_GA_ID

declare global {
  interface Window {
    gtag: any
  }
}

export const init = () => {
  window.gtag('js', new Date())
  // window.gtag('consent', 'default', {
  //   ad_storage: 'denied',
  //   analytics_storage: 'denied'
  // })
  window.gtag('config', GA_TRACKING_ID, {
    send_page_view: true,
    anonymize_ip: true
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, title: string) => {
  window.gtag('event', 'page_view', {
    page_title: title,
    page_location: url,
    send_to: GA_TRACKING_ID
  })
}

export const modalview = (action: 'open' | 'close', label: string) => {
  window.gtag('event', action, {
    event_category: 'modal',
    event_label: label
  })
}

export const setUser = (userID: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    user_id: userID,
    send_page_view: false,
    anonymize_ip: false
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action: string, category: string, label?: string) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label
  })
}

export const consent = (adStorage: boolean, analyticsStorage: boolean) => {
  window.gtag('consent', 'update', {
    ad_storage: adStorage ? 'granted' : 'denied',
    analytics_storage: analyticsStorage ? 'granted' : 'denied'
  })
}
