import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { ITime } from '../../../types'
import {
  GetTransactionsQuery,
  GetTransactionsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

interface IGraphData {
  id: string
  name: string
  color: string
  value: number
}

const GET_TRANSACTIONS = gql`
  query getTransactions($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getTransactions(frequency: $frequency, from: $from, to: $to) {
      growthRate
      total
      nbDebits
      nbCredits
    }
  }
`

const TransactionCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetTransactionsQuery,
    GetTransactionsQueryVariables
  >(GET_TRANSACTIONS, {
    variables: variablesCreator(time)
  })

  let formattedData: IGraphData[] = []

  if (data?.getTransactions) {
    formattedData = [
      {
        id: 'credit',
        name: 'Crédits',
        value: data.getTransactions?.nbCredits,
        color: '#1a78a8'
      },
      {
        id: 'debit',
        name: 'Débits',
        value: data.getTransactions?.nbDebits,
        color: '#fe6262'
      }
    ]
  }

  return (
    <LhCard
      title='Transactions'
      number={data?.getTransactions?.total || 0}
      loading={loading}
      hasContent
    >
      <ResponsiveContainer width='50%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default TransactionCardQuery
