import React from 'react'
import { ITime } from '../../../types'
import NewShopsCardQuery from './NewShopsCardQuery'
import NewShopsCardSubscription from './NewShopsCardSubscription'

const NewShopsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <NewShopsCardQuery time={time} />
  ) : (
    <NewShopsCardSubscription />
  )
}

export default NewShopsCard
