import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetAverageCashbacksQuery,
  GetAverageCashbacksQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

const GET_AVERAGE_CASHBACKS = gql`
  query getAverageCashbacks(
    $frequency: String!
    $from: DateTime!
    $to: DateTime!
  ) {
    getCashbacks(frequency: $frequency, from: $from, to: $to) {
      average
      amount
      nb
    }
  }
`

const AverageCashbackCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetAverageCashbacksQuery,
    GetAverageCashbacksQueryVariables
  >(GET_AVERAGE_CASHBACKS, {
    variables: variablesCreator(time)
  })

  return (
    <LhCard
      title='Cashback moyen'
      number={data?.getCashbacks?.average || 0}
      loading={loading}
      isPrice
    />
  )
}

export default AverageCashbackCardQuery
