import React, { useState } from 'react'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { ITime } from '../../../types'
import MapCardQuery from './MapCardQuery'
import MapCardSubscription from './MapCardSubscription'

const MapCard: React.FC<ITime> = ({ time }) => {
  const [mapObj, setMapObj] = useState<any>(null)
  const center = {
    lat: 46.227638,
    lng: 2.213749
  }

  return (
    <LhCard
      title='Carte'
      loading={false}
      hasContent
      showNumber={false}
      style={{ display: 'flex', height: 'calc(100% - 30px)' }}
    >
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
        version='beta'
      >
        <GoogleMap
          // @ts-ignore
          options={{ mapId: 'a0b34a47cb2e9c1a', streetViewControl: false }}
          center={center}
          zoom={5}
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          onLoad={(map) => setMapObj(map)}
        >
          {time !== 'live' ? (
            <MapCardQuery time={time} />
          ) : (
            <MapCardSubscription map={mapObj} />
          )}
        </GoogleMap>
      </LoadScript>
    </LhCard>
  )
}

export default MapCard
