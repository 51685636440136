const variablesCreator = (time: string | Date[]) => {
  if (typeof time === 'string') {
    return {
      frequency: time,
      from: '',
      to: ''
    }
  }

  return {
    frequency: '',
    from: time[0],
    to: time[1]
  }
}

export { variablesCreator }
