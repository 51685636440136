import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import {
  SubscribeCashbacksSubscription,
  SubscribeShopNewsRankSubscription
} from '../../../generated/graphql'
import shopPlaceholder from '../../../assets/img/Shop.svg'

const SUBSCRIBE_SHOP_NEWS_RANK = gql`
  subscription subscribeShopNewsRank {
    shopNewsRank {
      total
      shopNews {
        total
        shop {
          chain
          createdAt
          id
          logo
        }
      }
    }
  }
`

const PublicationsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeShopNewsRankSubscription>(
    SUBSCRIBE_SHOP_NEWS_RANK
  )

  let formattedData: LhPodiumItemProps[] = []

  if (data?.shopNewsRank) {
    formattedData = data.shopNewsRank.shopNews.map((detail, index) => ({
      id: index,
      picture: detail.shop.logo || shopPlaceholder,
      label: detail.shop.chain,
      value: detail.total.toString()
    }))
  }

  return (
    <LhCard
      title='Publications publiées'
      number={data?.shopNewsRank?.total}
      loading={false}
      hasContent
    >
      <LhPodium prefix='publications' items={formattedData} />
    </LhCard>
  )
}

export default PublicationsCardSubscription
