import React from 'react'
import { ITime } from '../../../types'
import AverageDebitsCardQuery from './AverageDebitsCardQuery'
import AverageDebitsCardSubscription from './AverageDebitsCardSubscription'

const AverageDebitsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <AverageDebitsCardQuery time={time} />
  ) : (
    <AverageDebitsCardSubscription />
  )
}

export default AverageDebitsCard
