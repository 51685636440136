import React from 'react'
import OutstandingCardQuery from './OutstandingCardQuery'
import OutstandingCardSubscription from './OutstandingCardSubscription'
import { ITime } from '../../../types'

const OutstandingCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <OutstandingCardQuery time={time} />
  ) : (
    <OutstandingCardSubscription />
  )
}

export default OutstandingCard
