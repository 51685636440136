import React from 'react'
import { ITime } from '../../../types'
import NewCustomersByShopCardQuery from './NewCustomersByShopCardQuery'
import NewCustomersByShopCardSubscription from './NewCustomersByShopCardSubscription'

const NewCustomersByShopCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <NewCustomersByShopCardQuery time={time} />
  ) : (
    <NewCustomersByShopCardSubscription />
  )
}

export default NewCustomersByShopCard
