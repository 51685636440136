import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts'
import {
  SubscribeCashbacksSubscription,
  SubscribeShopSubscriptionsSubscription
} from '../../../generated/graphql'

interface IGraphData {
  name: string
  nombre: number
  color: string
}

const SUBSCRIBE_SUBSCRIPTIONS = gql`
  subscription subscribeShopSubscriptions {
    shopSubscriptions {
      nb
      type
    }
  }
`

const mapDetailToGraph: any = {
  freemium: {
    label: 'Free',
    color: '#a7c8da'
  },
  starter: {
    label: 'Starter',
    color: '#9ed7ea'
  },
  medium: {
    label: 'Medium',
    color: '#1a78a8'
  },
  premium: {
    label: 'Premium',
    color: '#225d7b'
  },
  company: {
    label: 'Entreprise',
    color: '#14445d'
  },
  other: {
    label: 'Ancienne',
    color: '#d1d1d1'
  }
}

const SubscriptionsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeShopSubscriptionsSubscription>(
    SUBSCRIBE_SUBSCRIPTIONS
  )

  let formattedData: IGraphData[] = []

  if (data?.shopSubscriptions) {
    formattedData = data.shopSubscriptions.map((value) => ({
      name: mapDetailToGraph[value.type].label,
      nombre: value.nb,
      color: mapDetailToGraph[value.type].color
    }))
  }

  return (
    <LhCard title='Offres' loading={false} hasContent showNumber={false}>
      <ResponsiveContainer width='100%' height={220}>
        <BarChart data={formattedData}>
          <XAxis dataKey='name' />
          <Bar dataKey='nombre' fill='#8884d8' label={{ position: 'top' }}>
            {formattedData?.map((entry, index) => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Bar>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default SubscriptionsCardSubscription
