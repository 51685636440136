import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetAverageCashbacksQuery,
  GetAverageCashbacksQueryVariables,
  GetShopCustomersQuery,
  GetShopCustomersQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import shopPlaceholder from '../../../assets/img/Shop.svg'

const GET_SHOP_CUSTOMERS = gql`
  query getShopCustomers(
    $frequency: String!
    $from: DateTime!
    $to: DateTime!
  ) {
    getShopCustomers(frequency: $frequency, from: $from, to: $to, limit: 5) {
      total
      shop {
        chain
        id
        logo
      }
    }
  }
`
const NewCustomersByShopCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetShopCustomersQuery,
    GetShopCustomersQueryVariables
  >(GET_SHOP_CUSTOMERS, {
    variables: variablesCreator(time)
  })

  let formattedData: LhPodiumItemProps[] = []

  if (data?.getShopCustomers) {
    formattedData = data.getShopCustomers.map((detail, index) => ({
      id: +detail.shop.id,
      picture: detail.shop.logo || shopPlaceholder,
      label: detail.shop.chain,
      value: detail.total.toString(),
      rank: (index + 1).toString()
    }))
  }

  return (
    <LhCard
      title='Nouveaux clients par commerce'
      loading={loading}
      hasContent
      showNumber={false}
    >
      <LhPodium prefix='new-customers' items={formattedData} />
    </LhCard>
  )
}

export default NewCustomersByShopCardQuery
