import React from 'react'
import { ITime } from '../../../types'
import AverageCreditsCardQuery from './AverageCreditsCardQuery'
import AverageCreditsCardSubscription from './AverageCreditsCardSubscription'

const AverageCreditsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <AverageCreditsCardQuery time={time} />
  ) : (
    <AverageCreditsCardSubscription />
  )
}

export default AverageCreditsCard
