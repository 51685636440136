import React from 'react'
import AverageCreditsCard from './TransactionsAverageCards/AverageCreditsCard/AverageCreditsCard'
import AverageDebitsCard from './TransactionsAverageCards/AverageDebitsCard/AverageDebitsCard'
import AverageCashbacksCard from './TransactionsAverageCards/AverageCashbackCard/AverageCashbackCard'
import { ITime } from '../types'

const TransactionsAverageSlide: React.FC<ITime> = ({ time }) => (
  <div>
    <h2
      style={{
        color: '#4a4a4a',
        fontSize: 24,
        fontWeight: 300,
        letterSpacing: 0
      }}
    >
      Transactions
    </h2>
    <div className='content grid grid-rows-auto grid-cols-3 gap-4'>
      <div className='row-span-1 col-span-1'>
        <AverageCreditsCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <AverageDebitsCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <AverageCashbacksCard time={time} />
      </div>
    </div>
  </div>
)

export default TransactionsAverageSlide
