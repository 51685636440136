import React from 'react'
import { ITime } from '../../../types'
import TransactionCardQuery from './TransactionCardQuery'
import TransactionCardSubscription from './TransactionCardSubscription'

const TransactionCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <TransactionCardQuery time={time} />
  ) : (
    <TransactionCardSubscription />
  )
}

export default TransactionCard
