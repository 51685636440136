import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts'
import { ITime } from '../../../types'
import {
  GetCashbacksQuery,
  GetCashbacksQueryVariables,
  GetShopSubscriptionsQuery,
  GetShopSubscriptionsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

interface IGraphData {
  name: string
  nombre: number
  color: string
}

const GET_SUBSCRIPTIONS = gql`
  query getShopSubscriptions(
    $frequency: String!
    $from: DateTime!
    $to: DateTime!
  ) {
    getShopSubscriptions(frequency: $frequency, from: $from, to: $to) {
      nb
      type
    }
  }
`

const mapDetailToGraph: any = {
  freemium: {
    label: 'Free',
    color: '#a7c8da'
  },
  starter: {
    label: 'Starter',
    color: '#9ed7ea'
  },
  medium: {
    label: 'Medium',
    color: '#1a78a8'
  },
  premium: {
    label: 'Premium',
    color: '#225d7b'
  },
  company: {
    label: 'Entreprise',
    color: '#14445d'
  },
  other: {
    label: 'Ancienne',
    color: '#d1d1d1'
  }
}

const SubscriptionsCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetShopSubscriptionsQuery,
    GetShopSubscriptionsQueryVariables
  >(GET_SUBSCRIPTIONS, {
    variables: variablesCreator(time)
  })

  let formattedData: IGraphData[] = []

  if (data?.getShopSubscriptions) {
    formattedData = data.getShopSubscriptions.map((value) => ({
      name: mapDetailToGraph[value.type].label,
      nombre: value.nb,
      color: mapDetailToGraph[value.type].color
    }))
  }

  return (
    <LhCard title='Offres' loading={loading} hasContent showNumber={false}>
      <ResponsiveContainer width='100%' height={220}>
        <BarChart data={formattedData}>
          <XAxis dataKey='name' />
          <Bar dataKey='nombre' fill='#8884d8' label={{ position: 'top' }}>
            {formattedData?.map((entry, index) => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Bar>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default SubscriptionsCardQuery
