import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  formatPrice,
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import {
  GetDebitsQuery,
  GetDebitsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import { ITime } from '../../../types'

const GET_DEBITS = gql`
  query getDebits($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getDebits(frequency: $frequency, from: $from, to: $to, limit: 4) {
      nb
      amount
      average
      shopDetails {
        shop {
          id
          chain
          logo
        }
        nb
        average
        amount
      }
    }
  }
`

const DebitsCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetDebitsQuery,
    GetDebitsQueryVariables
  >(GET_DEBITS, {
    variables: variablesCreator(time)
  })

  let formattedData: LhPodiumItemProps[] = []

  if (data?.getDebits) {
    formattedData = data.getDebits.shopDetails.map((detail, index) => ({
      id: index,
      picture: detail.shop.logo || '',
      label: detail.shop.chain,
      value: formatPrice(detail.amount),
      rank: (index + 1).toString()
    }))
  }

  return (
    <LhCard
      title={`${data?.getDebits?.nb || 0} débits`}
      number={data?.getDebits?.amount || 0}
      loading={loading}
      hasContent
      isPrice
    >
      <LhPodium prefix='debits' items={formattedData} />
    </LhCard>
  )
}

export default DebitsCardQuery
