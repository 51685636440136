import React from 'react'
import { ITime } from '../../../types'
import SubscriptionsCardQuery from './SubscriptionsCardQuery'
import SubscriptionsCardSubscription from './SubscriptionsCardSubscription'

const SubscriptionsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <SubscriptionsCardQuery time={time} />
  ) : (
    <SubscriptionsCardSubscription />
  )
}

export default SubscriptionsCard
