import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import dayjs from 'dayjs'
import { ITime } from '../../../types'
import {
  GetCashbacksQuery,
  GetCashbacksQueryVariables,
  GetNewShopsQuery,
  GetNewShopsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import ShopPlaceholder from '../../../assets/img/Shop.svg'

const GET_NEW_SHOPS = gql`
  query getNewShops($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getNewShops(frequency: $frequency, from: $from, to: $to, limit: 4) {
      shops {
        chain
        id
        logo
        createdAt
      }
      total
    }
  }
`

const NewShopsCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetNewShopsQuery,
    GetNewShopsQueryVariables
  >(GET_NEW_SHOPS, {
    variables: variablesCreator(time)
  })

  let formattedData: LhPodiumItemProps[] = []

  if (data?.getNewShops) {
    formattedData = data.getNewShops.shops.map((detail, index) => ({
      id: index,
      picture: detail.logo || ShopPlaceholder,
      label: detail.chain,
      rank: dayjs(detail.createdAt).format('DD MMM YYYY')
    }))
  }

  return (
    <LhCard
      title='Nouveaux commerçants'
      number={data?.getNewShops?.total}
      loading={loading}
      hasContent
    >
      <LhPodium prefix='new-shops' items={formattedData} icon='lh-Calendar' />
    </LhCard>
  )
}

export default NewShopsCardQuery
