import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { SubscribeAverageCreditsSubscription } from '../../../generated/graphql'

const SUBSCRIBE_AVERAGE_CREDITS = gql`
  subscription subscribeAverageCredits {
    credits {
      average
    }
  }
`
const AverageCreditsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeAverageCreditsSubscription>(
    SUBSCRIBE_AVERAGE_CREDITS
  )

  return (
    <LhCard
      title='Crédit moyen'
      number={data?.credits?.average || 0}
      loading={false}
      isPrice
    />
  )
}

export default AverageCreditsCardSubscription
