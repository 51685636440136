import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { SubscribeTransactionsSubscription } from '../../../generated/graphql'

interface IGraphData {
  id: string
  name: string
  color: string
  value: number
}

const SUBSCRIBE_TRANSACTIONS = gql`
  subscription subscribeTransactions {
    transactions {
      growthRate
      total
      nbDebits
      nbCredits
    }
  }
`

const TransactionCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeTransactionsSubscription>(SUBSCRIBE_TRANSACTIONS)

  let formattedData: IGraphData[] = []

  if (data?.transactions) {
    formattedData = [
      {
        id: 'credit',
        name: 'Crédits',
        value: data.transactions?.nbCredits,
        color: '#1a78a8'
      },
      {
        id: 'debit',
        name: 'Débits',
        value: data.transactions?.nbDebits,
        color: '#fe6262'
      }
    ]
  }

  return (
    <LhCard
      title='Transactions'
      number={data?.transactions?.total || 0}
      loading={false}
      hasContent
    >
      <ResponsiveContainer width='50%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default TransactionCardSubscription
