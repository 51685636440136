import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import {
  SubscribeTransactionMapMarkerSubscription,
  TransactionMapMarker
} from '../../../generated/graphql'
import debitIcon from '../../../assets/img/Debit.svg'
import creditIcon from '../../../assets/img/Credit.svg'

const SUBSCRIBE_TRANSACTION_MAP_MARKER = gql`
  subscription subscribeTransactionMapMarker {
    transactionMapMarker {
      id
      amount
      date
      latitude
      longitude
      type
    }
  }
`

function addMaker(transactionMapMarker: TransactionMapMarker, map: any) {
  const marker = new google.maps.Marker({
    position: {
      lat: transactionMapMarker.latitude,
      lng: transactionMapMarker.longitude
    },
    icon:
      transactionMapMarker.type === 'credit'
        ? {
            url: creditIcon,
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0)
          }
        : {
            url: debitIcon,
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0)
          }
  })

  setTimeout(() => {
    marker.setMap(null)
  }, 5000)

  marker.setMap(map)
}

const MapCardSubscription: React.FC<{ map: any }> = ({ map }) => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeTransactionMapMarkerSubscription>(
    SUBSCRIBE_TRANSACTION_MAP_MARKER
  )

  if (data?.transactionMapMarker) {
    addMaker(data.transactionMapMarker, map)
  }

  return null
}

export default MapCardSubscription
