import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetCashbacksQuery,
  GetCashbacksQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

const GET_CASHBACKS = gql`
  query getCashbacks($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getCashbacks(frequency: $frequency, from: $from, to: $to) {
      nb
      amount
      average
    }
  }
`

const CashbackCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetCashbacksQuery,
    GetCashbacksQueryVariables
  >(GET_CASHBACKS, {
    variables: variablesCreator(time)
  })

  return (
    <LhCard
      title='Cashback offerts'
      number={data?.getCashbacks?.amount || 0}
      loading={loading}
      isPrice
    />
  )
}

export default CashbackCardQuery
