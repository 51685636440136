import React from 'react'
import { ITime } from '../../../types'
import CreditsCardQuery from './CreditsCardQuery'
import CreditsCardSubscription from './CreditsCardSubscription'

const CreditsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <CreditsCardQuery time={time} />
  ) : (
    <CreditsCardSubscription />
  )
}

export default CreditsCard
