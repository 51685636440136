import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import dayjs from 'dayjs'
import {
  SubscribeCashbacksSubscription,
  SubscribeNewShopsSubscription
} from '../../../generated/graphql'
import ShopPlaceholder from '../../../assets/img/Shop.svg'

const SUBSCRIBE_NEW_SHOPS = gql`
  subscription subscribeNewShops {
    newShops {
      shops {
        chain
        id
        logo
        createdAt
      }
      total
    }
  }
`
const NewShopsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeNewShopsSubscription>(SUBSCRIBE_NEW_SHOPS)

  let formattedData: LhPodiumItemProps[] = []

  if (data?.newShops) {
    formattedData = data.newShops.shops.map((detail, index) => ({
      id: index,
      picture: detail.logo || ShopPlaceholder,
      label: detail.chain,
      rank: dayjs(detail.createdAt).format('DD MMM YYYY')
    }))
  }

  return (
    <LhCard
      title='Nouveaux commerçants'
      number={data?.newShops?.total}
      loading={false}
      hasContent
    >
      <LhPodium prefix='new-shops' items={formattedData} icon='lh-Calendar' />
    </LhCard>
  )
}

export default NewShopsCardSubscription
