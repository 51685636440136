import React, { Suspense } from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import Store from 'store'
import { onError } from '@apollo/client/link/error'
import translations from './translations/fr.json'
import CurrentLocale from './currentLocale'
import Dashboard from './Dashboard'
import './assets/css/tailwind.css'
import './assets/sass/app.sass'
import * as gtag from './gtag'

const messages = {
  fr: translations
}

const urlParams = new URLSearchParams(window.location.search)

gtag.init()

if (!Store.get('lh_token') || urlParams.has('token')) {
  if (urlParams.get('token')) {
    Store.set('lh_token', urlParams.get('token'))
    window.history.replaceState({}, document.title, '/')
  } else {
    window.location.replace(
      `${process.env.REACT_APP_DASHBOARD_URL}/login?stats`
    )
  }
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_SUBSCRIPTION_URL || '',
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${Store.get('lh_token')}`
      }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${Store.get('lh_token')}`
    }
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map((err) => {
        // @ts-ignore
        switch (err?.statusCode) {
          case 401:
            return window.location.replace(
              `${process.env.REACT_APP_DASHBOARD_URL}/login?stats`
            )

          default:
            return false
        }
      })
    }
  }
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache()
})

const App: React.FC = () => {
  return (
    <Router>
      <IntlProvider locale='fr' messages={messages.fr}>
        <Suspense fallback={<h1>load</h1>}>
          <CurrentLocale />
          <ApolloProvider client={client}>
            <Dashboard />
          </ApolloProvider>
        </Suspense>
      </IntlProvider>
    </Router>
  )
}

export default App
