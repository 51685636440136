import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { formatPrice, LhCard, LhNumber } from '@leshabitues/leshabitues-ui'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import dayjs from 'dayjs'
import { ITime } from '../../../types'
import {
  GetOutstandingsQuery,
  GetOutstandingsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

interface IGraphData {
  name: string
  montant: number
}

const GET_OUTSTANDINGS = gql`
  query getOutstandings($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getOutstandings(frequency: $frequency, from: $from, to: $to) {
      amount
      graph {
        amount
        date
      }
    }
  }
`

const OutstandingCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetOutstandingsQuery,
    GetOutstandingsQueryVariables
  >(GET_OUTSTANDINGS, {
    variables: variablesCreator(time)
  })

  let formattedData: IGraphData[] = []

  if (data?.getOutstandings) {
    formattedData = data?.getOutstandings.graph.map((point) => ({
      name: dayjs(point.date).format('DD/MM/YYYY'),
      montant: point.amount
    }))
  }

  return (
    <LhCard title='Encours' loading={loading} hasContent showNumber={false}>
      <div className='flex flex-row'>
        <div className='flex flex-2'>
          <LhNumber number={data?.getOutstandings?.amount || 0} isPrice />
        </div>
        <div className='flex flex-1'>
          <ResponsiveContainer width='100%' height={30}>
            <LineChart data={formattedData}>
              <XAxis hide dataKey='name' />
              <YAxis hide />
              <Line
                type='monotone'
                dataKey='montant'
                stroke='#8884d8'
                strokeWidth={2}
              />
              <Tooltip
                contentStyle={{
                  border: 'none',
                  borderRadius: 4,
                  boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
                  padding: 10,
                  fontSize: 13
                }}
                formatter={(value, name, entry) => formatPrice(+value)}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </LhCard>
  )
}

export default OutstandingCardQuery
