import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { SubscribeAverageDebitsSubscription } from '../../../generated/graphql'

const SUBSCRIBE_AVERAGE_DEBITS = gql`
  subscription subscribeAverageDebits {
    debits {
      average
    }
  }
`

const AverageDebitsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeAverageDebitsSubscription>(
    SUBSCRIBE_AVERAGE_DEBITS
  )

  return (
    <LhCard
      title='Débit moyen'
      number={data?.debits?.average || 0}
      loading={false}
      isPrice
    />
  )
}

export default AverageDebitsCardSubscription
