import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { ITime } from '../../../types'
import {
  GetAverageCashbacksQuery,
  GetAverageCashbacksQueryVariables,
  GetGlobalCustomersQuery,
  GetGlobalCustomersQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

interface IGraphData {
  id: string
  name: string
  color: string
  value: number
}

const GET_GLOBAL_CUSTOMERS = gql`
  query getGlobalCustomers(
    $frequency: String!
    $from: DateTime!
    $to: DateTime!
  ) {
    getGlobalCustomers(frequency: $frequency, from: $from, to: $to) {
      total
      source {
        android
        ios
        shop
        web
      }
    }
  }
`

const mapDetailToGraph: any = {
  web: {
    label: 'Site Web',
    color: '#47b1d2'
  },
  android: {
    label: 'Android',
    color: '#14445d'
  },
  shop: {
    label: 'Commerce',
    color: '#9ed7ea'
  },
  ios: {
    label: 'iOS',
    color: '#1a78a8'
  }
}

const NewCustomersCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetGlobalCustomersQuery,
    GetGlobalCustomersQueryVariables
  >(GET_GLOBAL_CUSTOMERS, {
    variables: variablesCreator(time)
  })

  let formattedData: IGraphData[] = []

  if (data?.getGlobalCustomers) {
    formattedData = [
      {
        id: 'web',
        name: mapDetailToGraph.web.label,
        color: mapDetailToGraph.web.color,
        value: data.getGlobalCustomers.source.web
      },
      {
        id: 'android',
        name: mapDetailToGraph.android.label,
        color: mapDetailToGraph.android.color,
        value: data.getGlobalCustomers.source.android
      },
      {
        id: 'shop',
        name: mapDetailToGraph.shop.label,
        color: mapDetailToGraph.shop.color,
        value: data.getGlobalCustomers.source.shop
      },
      {
        id: 'ios',
        name: mapDetailToGraph.ios.label,
        color: mapDetailToGraph.ios.color,
        value: data.getGlobalCustomers.source.ios
      }
    ]
  }

  return (
    <LhCard
      title='Nouveaux clients'
      number={data?.getGlobalCustomers?.total || 0}
      loading={loading}
      hasContent
    >
      <ResponsiveContainer width='60%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData?.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default NewCustomersCardQuery
