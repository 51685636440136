import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import {
  SubscribeAverageCashbacksSubscription,
  SubscribeShopCustomersSubscription
} from '../../../generated/graphql'
import shopPlaceholder from '../../../assets/img/Shop.svg'

const SUBSCRIBE_SHOP_CUSTOMERS = gql`
  subscription subscribeShopCustomers {
    shopCustomers {
      total
      shop {
        chain
        id
        logo
      }
    }
  }
`
const NewCustomersByShopCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeShopCustomersSubscription>(
    SUBSCRIBE_SHOP_CUSTOMERS
  )

  let formattedData: LhPodiumItemProps[] = []

  if (data?.shopCustomers) {
    formattedData = data.shopCustomers.map((detail, index) => ({
      id: +detail.shop.id,
      picture: detail.shop.logo || shopPlaceholder,
      label: detail.shop.chain,
      value: detail.total.toString(),
      rank: (index + 1).toString()
    }))
  }

  return (
    <LhCard
      title='Nouveaux clients par commerce'
      loading={false}
      hasContent
      showNumber={false}
    >
      <LhPodium prefix='new-customers' items={formattedData} />
    </LhCard>
  )
}

export default NewCustomersByShopCardSubscription
