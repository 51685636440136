import React from 'react'
import { ITime } from '../../../types'
import AverageCashbackCardQuery from './AverageCashbackCardQuery'
import AverageCashbackCardSubscription from './AverageCashbackCardSubscription'

const AverageCashbacksCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <AverageCashbackCardQuery time={time} />
  ) : (
    <AverageCashbackCardSubscription />
  )
}

export default AverageCashbacksCard
