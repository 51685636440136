import React, { useState } from 'react'
import {
  LhCarousel,
  LhDatePicker,
  LhNavbar,
  LhPlayer
} from '@leshabitues/leshabitues-ui'
import TransactionsSlide from './Components/TransactionsSlide'
import TransactionsAverageSlide from './Components/TransactionsAverageSlide'
import CustomersAndMerchantsSlide from './Components/CustomersAndMerchantsSlide'

const leftItems = [
  {
    name: 'Commerces',
    icon: 'lh-Store',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/shops`,
    outsideDomain: true
  },
  {
    name: 'Commerçants',
    icon: 'lh-Account-Details',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/merchants`,
    outsideDomain: true
  },
  {
    name: 'Clients',
    icon: 'lh-Account-Circle',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/users`,
    outsideDomain: true
  },
  {
    name: 'Transactions',
    icon: 'lh-Transaction',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/transactions`,
    outsideDomain: true
  },
  {
    name: 'Actualités',
    icon: 'lh-Newspaper',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/news`,
    outsideDomain: true
  },
  {
    name: 'Messages',
    icon: 'lh-Chat',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/messaging`,
    outsideDomain: true
  }
]

const rightItems = [
  {
    name: 'Stats',
    icon: 'lh-Growth',
    link: `/`,
    isActive: () => true
  },
  {
    name: 'Stock',
    icon: 'lh-Stock',
    link: `${process.env.REACT_APP_DASHBOARD_URL}/equipments`,
    outsideDomain: true
  }
  // {
  //   name: '',
  //   icon: 'lh-Account-Circle-Filled',
  //   link: '',
  //   dropdown: [
  //     {
  //       icon: 'lh-LesHabitues',
  //       name: 'Mon compte',
  //       link: `${process.env.REACT_APP_DASHBOARD_URL}/`
  //     },
  //     {
  //       icon: 'lh-LesHabitues',
  //       name: 'Rapports',
  //       link: `${process.env.REACT_APP_DASHBOARD_URL}/`
  //     }
  //   ]
  // }
]

const Dashboard: React.FC = () => {
  const [time, setTime] = useState<string | Date[]>('live')
  const [currentSlide, setCurrentSlide] = useState(0)
  const nbSlides = 3

  function goPreviousSlide() {
    if (currentSlide === 0) {
      setCurrentSlide(nbSlides - 1)
    } else {
      setCurrentSlide((prevState) => prevState - 1)
    }
  }

  function goNextSlide() {
    if (currentSlide === nbSlides - 1) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide((prevState) => prevState + 1)
    }
  }

  return (
    <div>
      <LhNavbar
        logoLinkUrl='/'
        logoIconUrl='http://static.dev.leshabitues.fr/img/dashboard/lh.svg'
        leftItems={leftItems}
        rightItems={rightItems}
      />
      <div className='layout p-4'>
        <div className='header flex mt-4 mb-4'>
          <div className='flex flex-row items-center w-3/4'>
            <span className='text-4xl mr-8 text-text-gray-700 font-light'>
              Stats
            </span>
            <LhDatePicker onChange={(value) => setTime(value)} />
          </div>
          <div className='flex w-1/4 justify-end'>
            <LhPlayer
              nbSecond={5}
              slides={nbSlides}
              currentSlide={currentSlide}
              goNextSlide={goNextSlide}
              goPreviousSlide={goPreviousSlide}
            />
          </div>
        </div>
        <LhCarousel position={currentSlide}>
          <TransactionsSlide time={time} />
          <TransactionsAverageSlide time={time} />
          <CustomersAndMerchantsSlide time={time} />
        </LhCarousel>
      </div>
    </div>
  )
}

export default Dashboard
