import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetCashbacksQuery,
  GetCashbacksQueryVariables,
  GetShopNewsRankQuery,
  GetShopNewsRankQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import shopPlaceholder from '../../../assets/img/Shop.svg'

const GET_SHOP_NEWS_RANK = gql`
  query getShopNewsRank($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getShopNewsRank(frequency: $frequency, from: $from, to: $to, limit: 4) {
      total
      shopNews {
        total
        shop {
          chain
          createdAt
          id
          logo
        }
      }
    }
  }
`

const PublicationsCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetShopNewsRankQuery,
    GetShopNewsRankQueryVariables
  >(GET_SHOP_NEWS_RANK, {
    variables: variablesCreator(time)
  })

  console.log(data)

  let formattedData: LhPodiumItemProps[] = []

  if (data?.getShopNewsRank) {
    formattedData = data.getShopNewsRank.shopNews.map((detail, index) => ({
      id: index,
      picture: detail.shop.logo || shopPlaceholder,
      label: detail.shop.chain,
      value: detail.total.toString()
    }))
  }

  console.log(formattedData)

  return (
    <LhCard
      title='Publications publiées'
      number={data?.getShopNewsRank?.total}
      loading={loading}
      hasContent
    >
      <LhPodium prefix='publications' items={formattedData} />
    </LhCard>
  )
}

export default PublicationsCardQuery
