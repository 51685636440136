import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { SubscribePrepaymentsSubscription } from '../../../generated/graphql'

interface IGraphData {
  id: string
  name: string
  color: string
  value: number
}

const mapDetailToGraph: any = {
  web: {
    label: 'Site Web',
    color: '#47b1d2'
  },
  android: {
    label: 'Android',
    color: '#14445d'
  },
  shop: {
    label: 'Commerce',
    color: '#9ed7ea'
  },
  ios: {
    label: 'iOS',
    color: '#1a78a8'
  }
}

const SUBSCRIBE_PREPAYMENTS = gql`
  subscription subscribePrepayments {
    prepayments {
      amount
      sources {
        amount
        nb
        type
      }
    }
  }
`

const PrepaymentCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribePrepaymentsSubscription>(SUBSCRIBE_PREPAYMENTS)

  let formattedData: IGraphData[] = []

  if (data?.prepayments) {
    formattedData = data.prepayments.sources.map((source) => ({
      id: source.type,
      name: mapDetailToGraph[source.type].label,
      color: mapDetailToGraph[source.type].color,
      value: source.amount
    }))
  }

  return (
    <LhCard
      title='Prépaiement'
      number={data?.prepayments?.amount || 0}
      loading={false}
      hasContent
      isPrice
    >
      <ResponsiveContainer width='50%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData?.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )

  return null
}

export default PrepaymentCardSubscription
