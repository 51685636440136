import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import {
  formatPrice,
  LhCard,
  LhPodium,
  LhPodiumItemProps
} from '@leshabitues/leshabitues-ui'
import { SubscribeDebitsSubscription } from '../../../generated/graphql'

const SUBSCRIBE_DEBITS = gql`
  subscription subscribeDebits {
    debits {
      nb
      amount
      average
      shopDetails {
        shop {
          id
          chain
          logo
        }
        nb
        average
        amount
      }
    }
  }
`

const DebitsCardSubscription: React.FC = () => {
  const { loading, error, data } = useSubscription<SubscribeDebitsSubscription>(
    SUBSCRIBE_DEBITS
  )

  let formattedData: LhPodiumItemProps[] = []

  if (data?.debits) {
    formattedData = data.debits.shopDetails.map((detail, index) => ({
      id: index,
      picture: detail.shop.logo || '',
      label: detail.shop.chain,
      value: formatPrice(detail.amount),
      rank: (index + 1).toString()
    }))
  }

  return (
    <LhCard
      title={`${data?.debits?.nb || 0} débits`}
      number={data?.debits?.amount || 0}
      loading={false}
      hasContent
      isPrice
    >
      <LhPodium prefix='debits' items={formattedData} />
    </LhCard>
  )
}

export default DebitsCardSubscription
