import React from 'react'
import { ITime } from '../../../types'
import PrepaymentCardQuery from './PrepaymentCardQuery'
import PrepaymentCardSubscription from './PrepaymentCardSubscription'

const PrepaymentCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <PrepaymentCardQuery time={time} />
  ) : (
    <PrepaymentCardSubscription />
  )
}

export default PrepaymentCard
