import React from 'react'
import { Marker, MarkerClusterer } from '@react-google-maps/api'
import { gql, useQuery } from '@apollo/client'
import { ITime } from '../../../types'
import {
  GetMapDataQuery,
  GetMapDataQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import debitIcon from '../../../assets/img/Debit.svg'
import creditIcon from '../../../assets/img/Credit.svg'

const GET_MAP_DATA = gql`
  query getMapData($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getMapData(frequency: $frequency, from: $from, to: $to) {
      nbCredits
      nbDebits
      localisations {
        id
        latitude
        longitude
        nbCredits
        nbDebits
      }
    }
  }
`

const options = {
  imageExtension: 'svg',
  imagePath: `${process.env.REACT_APP_CDN_S3}/stats/clusters/credit-`
}
const MapCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetMapDataQuery,
    GetMapDataQueryVariables
  >(GET_MAP_DATA, {
    variables: variablesCreator(time)
  })

  const markers = data?.getMapData?.localisations || []

  return (
    <MarkerClusterer options={options}>
      {(clusterer) => {
        return markers.map((location, index) => (
          <Marker
            key={`marker-${location.id}`}
            position={{
              lat: location.latitude,
              lng: location.longitude
            }}
            clusterer={clusterer}
            icon={
              location.nbCredits > location.nbDebits
                ? {
                    url: creditIcon,
                    scaledSize: new google.maps.Size(10, 10),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0)
                  }
                : {
                    url: debitIcon,
                    scaledSize: new google.maps.Size(10, 10),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0)
                  }
            }
          />
        ))
      }}
    </MarkerClusterer>
  )
}

export default MapCardQuery
