import React from 'react'
import { ITime } from '../../../types'
import PublicationsCardQuery from './PublicationsCardQuery'
import PublicationsCardSubscription from './PublicationsCardSubscription'

const PublicationsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <PublicationsCardQuery time={time} />
  ) : (
    <PublicationsCardSubscription />
  )
}

export default PublicationsCard
