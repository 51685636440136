import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { SubscribeCashbacksSubscription } from '../../../generated/graphql'

const SUBSCRIBE_CASHBACKS = gql`
  subscription subscribeCashbacks {
    cashbacks {
      nb
      amount
      average
    }
  }
`

const CashbackCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeCashbacksSubscription>(SUBSCRIBE_CASHBACKS)

  return (
    <LhCard
      title='Cashback offerts'
      number={data?.cashbacks?.amount || 0}
      loading={false}
      isPrice
    />
  )
}

export default CashbackCardSubscription
