import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { SubscribeCreditsSubscription } from '../../../generated/graphql'

interface IGraphData {
  id: string
  name: string
  value: number
  color: string
}

const SUBSCRIBE_CREDITS = gql`
  subscription subscribeCredits {
    credits {
      nb
      amount
      details {
        cash {
          nb
          amount
        }
        cb {
          nb
          amount
        }
        tr {
          nb
          amount
        }
        check {
          nb
          amount
        }
      }
    }
  }
`

const mapDetailToGraph = {
  cash: {
    label: 'Espèces',
    color: '#47b1d2'
  },
  cb: {
    label: 'CB',
    color: '#14445d'
  },
  tr: {
    label: 'Titre restaurant',
    color: '#a7c8da'
  },
  check: {
    label: 'Chèque',
    color: '#1a78a8'
  }
}

const CreditsCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeCreditsSubscription>(SUBSCRIBE_CREDITS)

  let formattedData: IGraphData[] = []

  if (data?.credits) {
    formattedData = [
      {
        id: 'cash',
        name: mapDetailToGraph.cash.label,
        value: data?.credits.details.cash?.nb,
        color: mapDetailToGraph.cash.color
      },
      {
        id: 'cb',
        name: mapDetailToGraph.cb.label,
        value: data?.credits.details.cb?.nb,
        color: mapDetailToGraph.cb.color
      },
      {
        id: 'tr',
        name: mapDetailToGraph.tr.label,
        value: data?.credits.details.tr?.nb,
        color: mapDetailToGraph.tr.color
      },
      {
        id: 'check',
        name: mapDetailToGraph.check.label,
        value: data?.credits.details.check?.nb,
        color: mapDetailToGraph.check.color
      }
    ]
  }

  return (
    <LhCard
      title={`${data?.credits?.nb || 0} crédits`}
      number={data?.credits?.amount || 0}
      loading={false}
      hasContent
      isPrice
    >
      <ResponsiveContainer width='50%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default CreditsCardSubscription
