import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetAverageCreditsQuery,
  GetAverageCreditsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

const GET_AVERAGE_CREDITS = gql`
  query getAverageCredits(
    $frequency: String!
    $from: DateTime!
    $to: DateTime!
  ) {
    getCredits(frequency: $frequency, from: $from, to: $to) {
      average
    }
  }
`

const AverageCreditsCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetAverageCreditsQuery,
    GetAverageCreditsQueryVariables
  >(GET_AVERAGE_CREDITS, {
    variables: variablesCreator(time)
  })

  return (
    <LhCard
      title='Crédit moyen'
      number={data?.getCredits?.average || 0}
      loading={loading}
      isPrice
    />
  )
}

export default AverageCreditsCardQuery
