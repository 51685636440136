import React from 'react'
import NewCustomersCard from './CustomersCards/NewCustomersCard/NewCustomersCard'
import NewShopsCard from './MerchantsCards/NewShopsCard/NewShopsCard'
import NewCustomersByShopCard from './CustomersCards/NewCustomersByShopCard/NewCustomersByShopCard'
import PublicationsCard from './MerchantsCards/PublicationsCard/PublicationsCard'
import SubscriptionsCard from './MerchantsCards/SubscriptionsCard/SubscriptionsCard'

interface IProps {
  time: string | Date[]
}

const CustomersAndMerchantsSlide: React.FC<IProps> = ({ time }) => (
  <div>
    <h2
      style={{
        color: '#4a4a4a',
        fontSize: 24,
        fontWeight: 300,
        letterSpacing: 0
      }}
    >
      Clients
    </h2>
    <div className='content grid grid-rows-auto grid-cols-3 gap-4'>
      <div className='row-span-1 col-span-1'>
        <NewCustomersCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <NewCustomersByShopCard time={time} />
      </div>
      <div className='row-span-1 col-span-1' />
    </div>
    <h2
      style={{
        color: '#4a4a4a',
        fontSize: 24,
        fontWeight: 300,
        letterSpacing: 0
      }}
    >
      Commerçants
    </h2>
    <div className='content grid grid-rows-auto grid-cols-3 gap-4'>
      <div className='row-span-1 col-span-1'>
        <NewShopsCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <PublicationsCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <SubscriptionsCard time={time} />
      </div>
    </div>
  </div>
)

export default CustomersAndMerchantsSlide
