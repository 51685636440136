import React from 'react'
import { gql, useSubscription } from '@apollo/client'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { SubscribeAverageCashbacksSubscription } from '../../../generated/graphql'

const SUBSCRIBE_AVERAGE_CASHBACKS = gql`
  subscription subscribeAverageCashbacks {
    cashbacks {
      average
      amount
      nb
    }
  }
`

const AverageCashbackCardSubscription: React.FC = () => {
  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeAverageCashbacksSubscription>(
    SUBSCRIBE_AVERAGE_CASHBACKS
  )

  return (
    <LhCard
      title='Cashback moyen'
      number={data?.cashbacks?.average || 0}
      loading={false}
      isPrice
    />
  )
}

export default AverageCashbackCardSubscription
