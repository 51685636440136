import React from 'react'
import PrepaymentCard from './TransactionsCards/PrepaymentCard/PrepaymentCard'
import CashbackCard from './TransactionsCards/CashbackCard/CashbackCard'
import OutstandingCard from './TransactionsCards/OutstandingCard/OutstandingCard'
import TransactionCard from './TransactionsCards/TransactionCard/TransactionCard'
import CreditsCard from './TransactionsCards/CreditsCard/CreditsCard'
import DebitsCard from './TransactionsCards/DebitsCard/DebitsCard'
import { ITime } from '../types'
import MapCard from './TransactionsCards/MapCard/MapCard'

const TransactionsSlide: React.FC<ITime> = ({ time }) => (
  <div>
    <h2
      style={{
        color: '#4a4a4a',
        fontSize: 24,
        fontWeight: 300,
        letterSpacing: 0
      }}
    >
      Transactions
    </h2>
    <div className='content grid grid-rows-auto grid-cols-3 gap-4'>
      <div className='row-span-3 col-span-2'>
        <MapCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <PrepaymentCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <CashbackCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <OutstandingCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <TransactionCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <CreditsCard time={time} />
      </div>
      <div className='row-span-1 col-span-1'>
        <DebitsCard time={time} />
      </div>
    </div>
  </div>
)

export default TransactionsSlide
