import React, { useState } from 'react'
import { gql, useSubscription } from '@apollo/client'
import { formatPrice, LhCard, LhNumber } from '@leshabitues/leshabitues-ui'
import dayjs from 'dayjs'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { SubscribeOutstandingsSubscription } from '../../../generated/graphql'

interface IGraphData {
  name: string
  montant: number
}

const SUBSCRIBE_OUTSTANDINGS = gql`
  subscription subscribeOutstandings {
    outstandings {
      amount
      graph {
        amount
        date
      }
    }
  }
`

const OutstandingCardSubscription: React.FC = () => {
  const [formattedData, setFormattedData] = useState<IGraphData[]>([])

  const {
    loading,
    error,
    data
  } = useSubscription<SubscribeOutstandingsSubscription>(SUBSCRIBE_OUTSTANDINGS)

  if (
    data?.outstandings &&
    (formattedData.length === 0 ||
      (formattedData.length > 0 &&
        dayjs(data.outstandings.graph[0].date).format('DD/MM/YYYY') !==
          formattedData[formattedData.length - 1].name &&
        data.outstandings.graph[0].amount !==
          formattedData[formattedData.length - 1].montant))
  ) {
    setFormattedData([
      ...formattedData,
      ...data.outstandings.graph.map((point) => ({
        name: dayjs(point.date).format('DD/MM/YYYY'),
        montant: point.amount
      }))
    ])
  }

  return (
    <LhCard title='Encours' loading={false} hasContent showNumber={false}>
      <div className='flex flex-row'>
        <div className='flex flex-2'>
          <LhNumber number={data?.outstandings?.amount || 0} isPrice />
        </div>
        <div className='flex flex-1'>
          <ResponsiveContainer width='100%' height={30}>
            <LineChart data={formattedData}>
              <XAxis hide dataKey='name' />
              <YAxis hide />
              <Line
                type='monotone'
                dataKey='montant'
                stroke='#8884d8'
                strokeWidth={2}
              />
              <Tooltip
                contentStyle={{
                  border: 'none',
                  borderRadius: 4,
                  boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
                  padding: 10,
                  fontSize: 13
                }}
                formatter={(value, name, entry) => formatPrice(+value)}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </LhCard>
  )
}

export default OutstandingCardSubscription
