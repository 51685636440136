import React from 'react'
import { ITime } from '../../../types'
import DebitsCardQuery from './DebitsCardQuery'
import DebitsCardSubscription from './DebitsCardSubscription'

const DebitsCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <DebitsCardQuery time={time} />
  ) : (
    <DebitsCardSubscription />
  )
}

export default DebitsCard
