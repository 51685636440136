import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { LhCard } from '@leshabitues/leshabitues-ui'
import { ITime } from '../../../types'
import {
  GetPrepaymentsQuery,
  GetPrepaymentsQueryVariables
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'

interface IGraphData {
  id: string
  name: string
  color: string
  value: number
}

const GET_PREPAYMENTS = gql`
  query getPrepayments($frequency: String!, $from: DateTime!, $to: DateTime!) {
    getPrepayments(frequency: $frequency, from: $from, to: $to) {
      amount
      sources {
        amount
        nb
        type
      }
    }
  }
`

const mapDetailToGraph: any = {
  web: {
    label: 'Site Web',
    color: '#47b1d2'
  },
  android: {
    label: 'Android',
    color: '#14445d'
  },
  shop: {
    label: 'Commerce',
    color: '#9ed7ea'
  },
  ios: {
    label: 'iOS',
    color: '#1a78a8'
  }
}

const PrepaymentCardQuery: React.FC<ITime> = ({ time }) => {
  const { loading, error, data, subscribeToMore } = useQuery<
    GetPrepaymentsQuery,
    GetPrepaymentsQueryVariables
  >(GET_PREPAYMENTS, {
    variables: variablesCreator(time)
  })

  let formattedData: IGraphData[] = []

  if (data?.getPrepayments) {
    formattedData = data.getPrepayments.sources.map((source) => ({
      id: source.type,
      name: mapDetailToGraph[source.type].label,
      color: mapDetailToGraph[source.type].color,
      value: source.amount
    }))
  }

  return (
    <LhCard
      title='Prépaiement'
      number={data?.getPrepayments?.amount || 0}
      loading={loading}
      hasContent
      isPrice
    >
      <ResponsiveContainer width='50%' height={130}>
        <PieChart>
          <Pie data={formattedData} labelLine={false} dataKey='value'>
            {formattedData?.map((entry) => (
              <Cell key={entry.id} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              border: 'none',
              borderRadius: 4,
              boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
              padding: 10,
              fontSize: 13
            }}
          />
          <Legend
            layout='vertical'
            verticalAlign='middle'
            align='right'
            iconType='circle'
          />
        </PieChart>
      </ResponsiveContainer>
    </LhCard>
  )
}

export default PrepaymentCardQuery
