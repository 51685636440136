import React from 'react'
import { LhCard } from '@leshabitues/leshabitues-ui'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { gql, useQuery } from '@apollo/client'
import {
  GetGlobalCustomersQuery,
  GetGlobalCustomersQueryVariables,
  SubscribeGlobalCustomersSubscription
} from '../../../generated/graphql'
import { variablesCreator } from '../../../helper'
import { ITime } from '../../../types'
import NewCustomersCardQuery from './NewCustomersCardQuery'
import NewCustomersCardSubscription from './NewCustomersCardSubscription'

const NewCustomersCard: React.FC<ITime> = ({ time }) => {
  return time !== 'live' ? (
    <NewCustomersCardQuery time={time} />
  ) : (
    <NewCustomersCardSubscription />
  )
}

export default NewCustomersCard
